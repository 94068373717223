import React from 'react'
import Login from '../pages/Login'

const Auth = () => {
    return (
        <div>
            <Login />
        </div>
    )
}

export default Auth
